import React, { useContext, useEffect, useState } from 'react';
import {  PaymentStatusesStatus, PaymentStatusFilters, PaymentStatusDataForm } from '../../app/models/PaymentStatus'
import { ServiceResponse } from '../../app/services/shared/interfaces';
import Breadcrumbs, { BreadcrumbsItem } from '../../template/MainTheme/components/Breadcrumbs';
import DefaultCard from '../../components/default/DefaultCard';
import usePaymentStatusService from '../../app/services/hooks/usePaymentStatusService';
import { toast } from 'react-toastify';
import PaymentStatusDataTable from './components/tables/PaymentStatusDataTable';
import { AppContext } from '../../contexts/AppContext';
import useReactConfirmAlert from '../../hooks/useReactConfirmAlert';
import useNavigationPage from '../../hooks/useNavigationPage';


const breadcrumbs: BreadcrumbsItem[] = [
    {
        name: 'Estados de pago de venta',
        url: '/payment-statuses',
        isActive: true
    }
];

const PaymentStatuses = () => {
    const {
        fetchingGetPaymentStatuses,
        getPaymentStatuses,
        deletePaymentStatus,
        getDocumentPaymentStatus,
    } = usePaymentStatusService();
    const [paymentStatuses, setPaymentStatuses] = useState<PaymentStatusDataForm[]>([]);
    const [totalRows, setTotalRows] = useState<number>(0);
 
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { navigationPage } = useNavigationPage();

    const [statuses, setStatuses] = useState<PaymentStatusesStatus[]>([]);

    const [filter, setFilter] = useState<PaymentStatusFilters>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'desc'
    });

    useEffect(() => {
        getAllPaymentStatuses();
    }, [filter]);

    const getAllPaymentStatuses = () => {
        getPaymentStatuses(filter, {
            onSuccess: (response: ServiceResponse) => {
                setPaymentStatuses(response.data.payment_statuses);
                setTotalRows(response.data.total_rows);
                setStatuses(response.data.statuses)
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message);
                if (response.data.to_dashboard) {
                    navigationPage('/');
                }
            },
        });
    };

 

    const showEdit = (id: number) => {
        navigationPage(`/payment-statuses/${id}/edit`);
    };


    const reloadTable = () => {
        getAllPaymentStatuses();
    };

    const document = (file: string) => {
        window.open(file);
        // if (showLoading) showLoading('download', 'Recuperando documento...');
        // getDocumentPaymentStatus(paymentStatusId, {
        //     onSuccess: (message: string) => {
        //         if (changeAnimation) changeAnimation('downloadSuccess', message, true);
        //     },
        //     onError: (response: ServiceResponse) => {
        //         if (hideLoading) hideLoading();
        //
        //         useReactConfirmAlert().errorAlert({
        //             title: 'Error',
        //             message: response.message
        //         });
        //     }
        // });
    };

    const destroy = (PaymentStatusId: number) => {
        const _text = 'Está a punto de eliminar el estado de pago #' + PaymentStatusId;

        useReactConfirmAlert().requestConfirmation({
            title: '¿Está seguro?',
            message: _text,
            buttons: {
                confirmButton: {
                    label: 'Si, confirmar',
                    onClick: () => {
                        if (showLoading) showLoading('loading', 'Eliminando estado de pago...');
                        deletePaymentStatus(PaymentStatusId, {
                            onSuccess: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();
                                useReactConfirmAlert().successAlert({
        
                                    title: 'Éxito',
                                    message: response.message
                                });
                                reloadTable();
                            },
                            onError: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();
                                useReactConfirmAlert().errorAlert({
        
                                    title: 'Error',
                                    message: response.message
        
                                });
                            }
                        });
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: 'El estado de pago no se ha eliminado.'
                            });
                        }, 0);
                    }
                }
            }
        });
    };

    return (
        <>
            <Breadcrumbs pageSection="Estados de pago de venta" breadcrumbs={breadcrumbs} />

            <DefaultCard>
              
                <PaymentStatusDataTable
                    paymentStatuses={paymentStatuses}
                    totalRows={totalRows}
                    loading={fetchingGetPaymentStatuses}
                    edit={showEdit}
                    destroy={destroy}
                    documentPaymentStatus={document}
                    filter={filter}
                    setFilter={setFilter}
                    statuses={statuses}
                    callbackSuccess={reloadTable}
                />
            </DefaultCard>
        </>
    );
};

export default PaymentStatuses;


