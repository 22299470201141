import useFetch from '../../../hooks/useFetch';
import { useState } from 'react';
import { PaymentStatus, PaymentStatusFilters, PaymentStatusDataForm } from '../../models/PaymentStatus';
import { ServiceEvents, ServiceEventsDocuments } from '../shared/interfaces';
import { EndPoints } from '../EndPoints';
import { fetchDefaultHeaders } from '../shared/vars';
import { PaymentStatusLoad } from '../../models/PaymentStatusLoad';

const usePaymentStatusService = () => {
    const { doGet, doPost, doPut, doDelete, doGetDocument } = useFetch();

    const [fetchingGetPaymentStatuses, setFetchingGetPaymentStatuses] = useState(false);
    const [fetchingShowPaymentStatus, setFetchingShowPaymentStatus] = useState(false);
    const [fetchingCreatePaymentStatus, setFetchingCreatePaymentStatus] = useState(false);
    const [fetchingStorePaymentStatus, setFetchingStorePaymentStatus] = useState(false);
    const [fetchingEditPaymentStatus, setFetchingEditPaymentStatus] = useState(false);
    const [fetchingUpdatePaymentStatus, setFetchingUpdatePaymentStatus] = useState(false);
    const [fetchingDeletePaymentStatus, setFetchingDeletePaymentStatus] = useState(false);
    const [fetchingGeneratePaymentStatus, setFetchingGeneratePaymentStatus] = useState(false);
    const [fetchingGetDocumentPaymentStatus, setFetchingGetDocumentPaymentStatus] = useState(false);
    const [fetchingChangeStatusPaymentStatus, setFetchingChangeStatusPaymentStatus] = useState(false);
    const [fetchingCanChangeStatusPaymentStatus, setFetchingCanChangeStatusPaymentStatus] = useState(false);

    const getPaymentStatuses = (filter: PaymentStatusFilters, events: ServiceEvents = {}) => {
        const queryString = Object.entries(filter)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.PAYMENT_STATUS.GET_PAYMENT_STATUSES}?${queryString}`;
        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetPaymentStatuses
        });
    };

    // const getDocumentPaymentStatus = (id: number, events: ServiceEvents = {}) => {
    //     fetch(EndPoints.PAYMENT_STATUS.GET_PAYMENT_DOCUMENT.replace(':id', id.toString()), {
    //         headers: { ...fetchDefaultHeaders }
    //     }).then((response) => {
    //         response.blob().then((blob) => {
    //             const a = document.createElement('a');
    //             const url = window.URL.createObjectURL(blob);
    //             a.href = url;
    //             a.download = `estado de pago n°${id}.docx`;
    //             a.click();
    //             window.URL.revokeObjectURL(url);
    //         });
    //     });
    // };

    const getDocumentPaymentStatus = (id: number, events: ServiceEventsDocuments = {}) => {
        const url = EndPoints.PAYMENT_STATUS.GET_PAYMENT_DOCUMENT.replace(':id', id.toString());

        doGetDocument({
            ...events,
            url: url,
            successData: {
                nameDocument: `estado de pago n°${id}.docx`,
                message: 'Descarga exitosa.'
            },
            setFetching: setFetchingGetDocumentPaymentStatus
        });
    };

    const showPaymentStatus = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.PAYMENT_STATUS.GET_PAYMENT_STATUS.replace(':id', id.toString()),
            setFetching: setFetchingShowPaymentStatus
        });
    };

    const createPaymentStatus = (events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.PAYMENT_STATUS.CREATE_PAYMENT_STATUS,
            setFetching: setFetchingCreatePaymentStatus
        });
    };

    const storePaymentStatus = (paymentStatus: PaymentStatus, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.PAYMENT_STATUS.STORE_PAYMENT_STATUS,
            body: paymentStatus,
            setFetching: setFetchingStorePaymentStatus
        });
    };

    const generatePaymentStatusDocument = (load: PaymentStatusLoad, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.PAYMENT_STATUS.GENERATE_PAYMENT_STATUS,
            body: load,
            setFetching: setFetchingGeneratePaymentStatus
        });
    };

    const editPaymentStatus = (id: number, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.PAYMENT_STATUS.EDIT_PAYMENT_STATUS.replace(':id', id.toString()),
            setFetching: setFetchingEditPaymentStatus
        });
    };

    const updatePaymentStatus = (
        id: number,
        paymentStatus: PaymentStatusDataForm,
        events: ServiceEvents = {}
    ) => {
        doPut({
            ...events,
            url: EndPoints.PAYMENT_STATUS.UPDATE_PAYMENT_STATUS.replace(':id', id.toString()),
            body: paymentStatus,
            setFetching: setFetchingUpdatePaymentStatus
        });
    };

    const deletePaymentStatus = (id: number, events: ServiceEvents = {}) => {
        doDelete({
            ...events,
            url: EndPoints.PAYMENT_STATUS.DELETE_PAYMENT_STATUS.replace(':id', id.toString()),
            setFetching: setFetchingDeletePaymentStatus
        });
    };

    const canChangePaymentStatuses = (id: number, status: string, events: ServiceEvents = {}) => {
        doGet({
            ...events,
            url: EndPoints.PAYMENT_STATUS.CAN_CHANGE_PAYMENT_STATUS.replace(
                ':id',
                id.toString()
            ).replace(':status', status),
            setFetching: setFetchingCanChangeStatusPaymentStatus
        });
    };

    const changeStatusPaymentStatus = (id: number, status: string, events: ServiceEvents = {}) => {
        doPut({
            ...events,
            url: EndPoints.PAYMENT_STATUS.CHANGE_STATUS_PAYMENT_STATUS.replace(
                ':id',
                id.toString()
            ),
            body: { status: status },
            setFetching: setFetchingChangeStatusPaymentStatus
        });
    };

    const changeReviewStatusPaymentStatus = (id: number, status: string, events: ServiceEvents = {}) => {
        doPut({
            ...events,
            url: EndPoints.PAYMENT_STATUS.CHANGE_REVIEW_STATUS_PAYMENT_STATUS.replace(
                ':id',
                id.toString()
            ),
            body: { status: status },
            setFetching: setFetchingChangeStatusPaymentStatus
        });
    };

    const changeApprovedStatusPaymentStatus = (id: number, status: string, events: ServiceEvents = {}) => {
        doPut({
            ...events,
            url: EndPoints.PAYMENT_STATUS.CHANGE_APPROVED_STATUS_PAYMENT_STATUS.replace(
                ':id',
                id.toString()
            ),
            body: { status: status },
            setFetching: setFetchingChangeStatusPaymentStatus
        });
    };

    const changeAnnulledStatusPaymentStatus = (id: number, status: string, events: ServiceEvents = {}) => {
        doPut({
            ...events,
            url: EndPoints.PAYMENT_STATUS.CHANGE_ANNULLED_STATUS_PAYMENT_STATUS.replace(
                ':id',
                id.toString()
            ),
            body: { status: status },
            setFetching: setFetchingChangeStatusPaymentStatus
        });
    };

    const changeDraftStatusPaymentStatus = (id: number, status: string, events: ServiceEvents = {}) => {
        doPut({
            ...events,
            url: EndPoints.PAYMENT_STATUS.CHANGE_DRAFT_STATUS_PAYMENT_STATUS.replace(
                ':id',
                id.toString()
            ),
            body: { status: status },
            setFetching: setFetchingChangeStatusPaymentStatus
        });
    };

    return {
        fetchingGetPaymentStatuses,
        fetchingShowPaymentStatus,
        fetchingCreatePaymentStatus,
        fetchingStorePaymentStatus,
        fetchingEditPaymentStatus,
        fetchingUpdatePaymentStatus,
        fetchingDeletePaymentStatus,
        fetchingGeneratePaymentStatus,
        fetchingChangeStatusPaymentStatus,
        fetchingCanChangeStatusPaymentStatus,
        getPaymentStatuses,
        showPaymentStatus,
        createPaymentStatus,
        storePaymentStatus,
        editPaymentStatus,
        updatePaymentStatus,
        deletePaymentStatus,
        getDocumentPaymentStatus,
        changeStatusPaymentStatus,
        canChangePaymentStatuses,
        generatePaymentStatusDocument,
        changeReviewStatusPaymentStatus,
        changeApprovedStatusPaymentStatus,
        changeAnnulledStatusPaymentStatus,
        changeDraftStatusPaymentStatus
    };
};

export default usePaymentStatusService;
