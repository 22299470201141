import { Bank } from './Bank';
import { User } from './User';

export interface Client {
    id?: number;
    rut: string;
    email?: string;
    business_name: string;
    first_name_contact: string;
    last_name_contact: string;
    second_last_name_contact: string;
    email_contact: string;
    phone_code_contact: string;
    phone_contact: string;
    address: string;
    country?: string;
    is_active: boolean;
    user_id?: number;
    user?: User;
}

export interface ClientFilters {
    client_id?: number | null;
    business_name: string | null;
    rut?: string | null;
    email?: string | '';
    page: number;
    per_page: number;
    sort: string;
    order: string;
}

export const defaultClientDataForm: Client = {
    rut: '',
    business_name: '',
    first_name_contact: '',
    last_name_contact: '',
    second_last_name_contact: '',
    email_contact: '',
    phone_code_contact: '',
    phone_contact: '',
    address: '',
    is_active: true
};

export interface ClientDataForm {
    id?: number;
    rut: string;
    business_name: string;
    first_name_contact: string;
    last_name_contact: string;
    second_last_name_contact: string;
    email_contact: string;
    phone_code_contact: string;
    phone_contact: string;
    address: string;
    country?: string;
    is_active: boolean;
    user_id?: number;
    user?: User;
}
