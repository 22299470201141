import React, { useContext, useEffect, useState } from 'react';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { useHistory } from 'react-router-dom';
import useDispatchProgramItemService from '../../../app/services/hooks/useDispatchProgramItemService';
import MassiveLoadForm from './forms/MassiveLoadForm';
import { PaymentStatusLoad, defaultPaymentStatusLoad } from '../../../app/models/PaymentStatusLoad';
import usePaymentStatusService from '../../../app/services/hooks/usePaymentStatusService';
import PaymentStatusForm from './forms/PaymentStatusForm';

interface Props {
    dispatch_program_id: number;
    transportation_order_item_id: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const PaymentStatusLoadCreate = ({
    dispatch_program_id,
    transportation_order_item_id,
    onSaved,
    onCancel,
    onError
}: Props) => {
    const { showLoading, hideLoading, changeAnimation, showProgressiveLoading } =
        useContext(AppContext);

    const { generatePaymentStatusDocument, fetchingGeneratePaymentStatus } =
        usePaymentStatusService();
    const [load, setLoad] = useState<PaymentStatusLoad>({
        ...defaultPaymentStatusLoad,
        dispatch_program_id: dispatch_program_id,
        transportation_order_item_id: transportation_order_item_id
    });

    const [errorFields, setErrorFields] = useState<any>();

    const generatePaymentStatuses = () => {
        if (showLoading)showLoading('loading' ,'Generando estados de pago...');

        generatePaymentStatusDocument(load, {
            onSuccess: (response) => {
        
                if (hideLoading) hideLoading();
            
                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                if (onSaved) onSaved();
          
            },
            onError: (response) => {
                if (hideLoading) hideLoading();
             
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
                if (onError) onError();
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    // if (errorGetData !== '') {
    //     return <div>{errorGetData}</div>;
    // }

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <PaymentStatusForm load={load} setLoad={setLoad} errorFields={errorFields} />
                </div>
            </div>
            <hr />
            <div className="text-center">
                <p>¿Está seguro de generar los estados de pago?</p>
            </div>

            <div className="row justify-content-center">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetchingGeneratePaymentStatus} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm
                        callbackSave={generatePaymentStatuses}
                        locked={fetchingGeneratePaymentStatus}
                    />
                </div>
            </div>
        </>
    );
};

export default PaymentStatusLoadCreate;
