import React, { useContext, useEffect, useState } from 'react';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import LazyLoading from '../../../components/LazyLoading';
import { SocietyDataForm, defaultSocietyForm } from '../../../app/models/Society';
import useSocietyService from '../../../app/services/hooks/useSocietyService';
import { Tab, Tabs } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { User } from '../../../app/models/User';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Breadcrumbs, { BreadcrumbsItem } from '../../../template/MainTheme/components/Breadcrumbs';
import DefaultCard from '../../../components/default/DefaultCard';
import SocietyForm from './forms/SocietyForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { DocumentSet } from '../../../app/models/DocumentSet';
import SocietyDocumentContainer from './SocietyDocumentContainer';
import SocietyClientContainer from './SocietyClientContainer';
import SocietyProviderContainer from './SocietyProviderContainer';

type RouterParams = {
    id: string;
};

const SocietyEdit = () => {
    const history = useHistory();
    const location = useLocation();
    const { id } = useParams<RouterParams>();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { fetchingEditSociety, editSociety, fetchingUpdateSociety, updateSociety } =
        useSocietyService();

    const [name, setName] = useState('');
    const [users, setUsers] = useState<User[]>([]);
    const [documentSets, setDocumentSets] = useState<DocumentSet[]>([]);
    const [society, setSociety] = useState<SocietyDataForm>(defaultSocietyForm);
    const [errorFields, setErrorFields] = useState<any>();


    useEffect(() => {
        edit(parseInt(id));
    }, [id]);


    const edit = (id: number) => {
        if (showLoading) showLoading('loading', 'Cargando sociedad...');
        editSociety(id, {
            onSuccess: (response: ServiceResponse) => {
                setName(response.data.society.business_name);
                setUsers(response.data.users);
                setDocumentSets(response.data.document_sets);
                setSociety(response.data.society);

                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                history.goBack();
            }
        });
    };

    const update = () => {
        if (showLoading) showLoading('loading', 'Actualizando sociedad...');
        updateSociety(Number(society?.id), society, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                setErrorFields(undefined);
                edit(parseInt(id));
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const breadcrumbs: BreadcrumbsItem[] = [
        {
            name: 'Sociedades',
            url: '/societies',
            isActive: false
        },
        {
            name: 'Editar #' + id,
            url: '/societies/' + id + '/edit',
            isActive: true
        }
    ];

    if (fetchingEditSociety) {
        return (
            <DefaultCard>
                <LazyLoading height={300} />
            </DefaultCard>
        );
    }


    return (
        <>
            <Breadcrumbs pageSection={`Editar - ${name}`} breadcrumbs={breadcrumbs} />

            {/* Tabs */}

            <Tabs defaultActiveKey="general-data" id="quote-tabs" className="mb-3">
                <Tab eventKey="general-data" title="Datos Generales">
                    <div className="row">
                        <div className="col-md-6">
                            <DefaultCard>
                                <div className="h3 mt-0 mb-2 card-title">Datos Generales</div>
                                {society && (
                                    <SocietyForm
                                        societyForm={society}
                                        setSocietyForm={setSociety}
                                        users={users}
                                        isEdit={true}
                                        documentSets={documentSets}
                                        errorFields={errorFields}
                                        setErrorFields={setErrorFields}
                                    />
                                )}
                                <div className="row justify-content-end">
                                    <div className="col-auto">
                                        <ButtonSaveForm
                                            callbackSave={update}
                                            locked={fetchingUpdateSociety}
                                        />
                                    </div>
                                </div>
                            </DefaultCard>
                        </div>
                        <>
                            <Tabs defaultActiveKey="documents" id="society-edit" className="mb-3">
                                <Tab eventKey="documents" title="Documentos">
                                    <SocietyDocumentContainer
                                        societyId={parseInt(id)}
                                    />
                                </Tab>
                                <Tab eventKey="clients" title="Clientes">
                                    <SocietyClientContainer
                                        societyId={parseInt(id)}
                                    />
                                </Tab>
                                <Tab eventKey="providers" title="Proveedores">
                                    <SocietyProviderContainer
                                        societyId={parseInt(id)}
                                    />
                                </Tab>
                            </Tabs>
                        </>
                    </div>
                </Tab>
            </Tabs>
        </>
    );
};

export default SocietyEdit;
