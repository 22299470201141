import { Place } from './Place';
import { Product } from './Product';
import { Unit } from './Unit';
import { Currency } from './Currency';
import { Service } from './Service';
import { Carrier } from './Carrier';
import { TransportOrder } from './TransportOrder';
import { Guide } from './Guide';
import { StatusRows } from '../shared/interfaces';

export interface DispatchProgramItem {
    dispatch_program_id?: number;
    id?: number;
    transportation_order_item_id?: number;
    dispatch_date: string;
    description: string;

    product_name: string;
    product_price: number;
    product_unit: string;
    quantity: number;

    status?: string;
    status_choices?: DispatchProgramItemStatus[];
    status_selected?: DispatchProgramItemStatus;
    rate?: number;
    total?: number;

    service_name?: string;
    currency_name?: string;
    origin_name?: string;
    destiny_name?: string;

    created_at?: Date | string;
    updated_at?: Date | string;
    deleted_at?: Date | string;

    currency?: Currency;
    currency_id?: number;

    destiny_id?: number;
    destiny?: Place;

    origin_id?: number;
    origin?: Place;

    product_id?: number;
    product?: Product;

    quantity_unit_id?: number;
    quantity_unit?: Unit;

    service_id?: number;
    service?: Service;

    carrier_id?: number;
    carrier?: Carrier;
    carrier_name?: string;
    guide?: Guide;

    transport_order?: TransportOrder;
    transport_order_id?: number;
    position?: number;
}

export interface DispatchProgramItemChangeStatusDispatch {
    folio?: string;
    total?: number;
    file?: File;
}

export interface DispatchProgramItemWithTransportOrder {
    dispatch_program_item_id?: number;
    transport_order_id?: number;
    provider_id?: number;
    is_internal?: boolean;
}

export interface DispatchProgramItemFilters {
    dispatch_program_item_status?: string | '';
    start_date?: string | '';
    end_date?: string | '';

    page: number;
    per_page: number;
    sort: string;
    order: string;
}

export interface DispatchProgramItemStatus {
    id: number | string;
    name: number | string;
}

export const defaultDispatchProgramItem: DispatchProgramItem = {
    dispatch_program_id: 0,
    id: undefined,
    description: '',
    service: undefined,
    service_id: 0,
    dispatch_date: '',
    product_name: '',
    product_price: 0,
    product_unit: '',
    quantity: 0,
    rate: 0,
    destiny_id: 0,
    destiny: undefined,
    origin_id: 0,
    origin: undefined,
    product_id: 0,
    product: undefined,
    quantity_unit_id: 0,
    quantity_unit: undefined,
    currency_id: 0,
    currency: undefined,
    currency_name: '',
    carrier_id: 0,
    carrier: undefined,
    carrier_name: '',
    status: '',
    created_at: '',
    updated_at: ''
};

export interface DispatchProgramItemShowDataForm {
    id?: number;
    description?: string;
    dispatch_date?: string;
    product_name?: string;
    product_price?: number;
    product_unit?: string;
    quantity?: string;
    quantity_unit?: string;
    rate?: string;
    service?: string;
    currency?: string;
    destiny?: string;
    origin?: string;
    created_at?: string;
    updated_at?: string;
}

export interface DispatchProgramDailyCarrierGuide {
    dispatch_program_item_id: number;
    provider_id?: number;
    carrier_id?: number;
    truck_id?: number;
    hopper_id?: number;
    folio?: string;
    issue_date?: string;
    quantity?: number;
    file?: File;
    tara_total?: number;
}

export interface MassiveStatusForm {
    dispatch_program_id?: number;
    status?: string;
    all_selected?: boolean;
    selected_count?: number;
    rows_ids?: number[];
}

export const dispatchProgramItemStatuses: StatusRows[] = [
    {
        value: 'PREPARACION',
        label: 'Preparación'
    },
    {
        value: 'PLANIFICACION',
        label: 'Planificación'
    },
    {
        value: 'DESPACHO',
        label: 'Despacho'
    },
    {
        value: 'FINALIZADO',
        label: 'Finalizado'
    }
];
