import React, { useEffect, useState } from 'react';
import { SocietyDataForm } from '../../../../app/models/Society';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import useRut from '../../../../hooks/useRut';
import { User } from '../../../../app/models/User';
import { Provider } from '../../../../app/models/Provider';
import SelectTwo, { SelectTwoMapperOptions } from '../../../../components/SelectTwo';
import { SocietyClientDataForm } from '../../../../app/models/SocietyClient';
// import { ClientContact } from '../../../../app/models/ClientContact';
import { Client } from '../../../../app/models/Client';
import LazyLoading from '../../../../components/LazyLoading';

interface GeneralSocietyProps {
    societyClientForm: SocietyClientDataForm;
    setSocietyClientForm: (societyClientForm: SocietyClientDataForm) => void;
    clients: Client[];
    // clientContacts: ClientContact[];
    errorFields?: any;
    setErrorFields?: (errors: any) => void;
    loadingClientContact?: boolean;
}

const SocietyClientForm = ({
    societyClientForm,
    setSocietyClientForm,
    clients,
    // clientContacts,
    errorFields,
    setErrorFields,
    loadingClientContact
}: GeneralSocietyProps) => {

    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();
    const { formatRut } = useRut();

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);


    const handleChange = (
        event:
            | React.ChangeEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLSelectElement>
            | React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        const { name, value } = event.target;

        setSocietyClientForm({ ...societyClientForm, [name]: value });
    };

    const handleChangeSelectTwo = (name: string, value: number | string | null) => {
        const obj = { [name]: value };

        if(name === 'client_id'){

            const selectedClient = clients.find((client: Client) => client.id === value);

            // if (selectedClient) {
                
            //     obj.account_number = selectedClient.account_number ?? '';
            // }
        }

        setSocietyClientForm({ ...societyClientForm, ...obj });
    };


    return (
        <>
            <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Cliente</label>
                    <SelectTwo
                        name="client_id"
                        id="client_id"
                        inputValue={societyClientForm.client_id}
                        options={SelectTwoMapperOptions(clients, 'id', ['business_name'])}
                        hasError={fieldHasError('client_id') !== ''}
                        onChange={(value: any) =>
                            handleChangeSelectTwo('client_id', value.value)
                        }
                        placeholder={'Seleccione cliente'}
                        onFocus={() => onFocusRemove('client_id')}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('client_id')}
                    </div>
                </div>
            </div>
            {/* {
                loadingClientContact ?
                <LazyLoading height={300} />
                :
                <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Contacto Cliente</label>
                    <SelectTwo
                        name="business_name_contact_id"
                        id="business_name_contact_id"
                        inputValue={societyClientForm.business_name_contact_id}
                        options={SelectTwoMapperOptions(clientContacts, 'id', ['name'])}
                        hasError={fieldHasError('business_name_contact_id') !== ''}
                        onChange={(value: any) =>
                            handleChangeSelectTwo('business_name_contact_id', value?.value)
                        }
                        placeholder={'Seleccione contacto'}
                        onFocus={() => onFocusRemove('business_name_contact_id')}
                        isClearable={true}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('business_name_contact_id')}
                    </div>
                </div>
            </div>
            } */}

            <div className="mb-2 row">
                <label className="col-form-label">N° cuenta corriente</label>
                <div className="col-md-12">
                    <input
                        name="account_number"
                        id="account_number"
                        type="text"
                        className={`form-control ${fieldHasError('account_number')}`}
                        value={societyClientForm.account_number}
                        onChange={handleChange}
                        onFocus={() => onFocusRemove('account_number')}
                        placeholder="Ingrese n° de cuenta"
                        // readOnly={true}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('account_number')}
                    </div>
                </div>
            </div>
        </>
    );
};

export default SocietyClientForm;
