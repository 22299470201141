import React, { useContext, useEffect, useRef, useState } from 'react';
import useDispatchProgramItemService from '../../../app/services/hooks/useDispatchProgramItemService';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { Carrier } from '../../../app/models/Carrier';
import useHandleErrorFields from '../../../hooks/useHandleErrorFields';
import DispatchProgramItemDailyCarrierForm from './forms/DispatchProgramItemDailyCarrierForm';
import DispatchProgramItemGuideForm from './forms/DispatchProgramItemGuideForm';
import { Provider } from '../../../app/models/Provider';
import { Truck } from '../../../app/models/Truck';
import { Hopper } from '../../../app/models/Hopper';
import { DailyCarrier } from '../../../app/models/DailyCarrier';
import { Guide, GuideDataForm } from '../../../app/models/Guide';
import { DispatchProgramItemWithTransportOrder } from '../../../app/models/DispatchProgramItem';
import { TransportOrder } from '../../../app/models/TransportOrder';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { AppContext } from '../../../contexts/AppContext';
import useTransportOrderService from '../../../app/services/hooks/useTransportOrderService';

interface Props {
    dispatchProgramItemId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: (message?: string) => void;
}

const DispatchProgramItemCarrierGuideContainer = ({
    dispatchProgramItemId,
    onSaved,
    onCancel,
    onError
}: Props) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();
    const { showLoading, hideLoading, changeAnimation, showProgressiveLoading } =
        useContext(AppContext);
    const {
        getDispatchProgramItemDailyCarriersAvailable,
        updateDispatchProgramCarrierGuide,
        fetchingGetDispatchProgramItemDailyCarriersAvailable,
        fetchingSyncDispatchProgramDailyCarriers,
        getDispatchProgramItemGuide,
        fetchingGetDispatchProgramItemGuide
    } = useDispatchProgramItemService();

    const [errorFields, setErrorFields] = useState<any>();
    const [dailyCarrier, setDailyCarrier] = useState<DailyCarrier>({});
    const [providers, setProviders] = useState<Provider[]>([]);
    const [trucks, setTrucks] = useState<Truck[]>([]);
    const [hoppers, setHopper] = useState<Hopper[]>([]);
    const [carriers, setCarriers] = useState<Carrier[]>([]);

    const [guide, setGuide] = useState<GuideDataForm>({});

    const {
        editDispatchProgramItemWithTransportOrder,
        fetchingEditDispatchProgramItemWithTransportOrder,
        updateDispatchProgramItemWithTransportOrder,
        fetchingUpdateDispatchProgramItemWithTransportOrder
    } = useDispatchProgramItemService();

    const { getTransportOrderByIdWithGuide } = useTransportOrderService();

    const [dispatchProgramWithTransportOrder, setDispatchProgramWithTransportOrder] =
        useState<DispatchProgramItemWithTransportOrder>({
            dispatch_program_item_id: dispatchProgramItemId,
            transport_order_id: 0,
            is_internal: true
        });
    const [transportOrders, setTransportOrders] = useState<TransportOrder[]>([]);

    const transportOrderIdSelected = useRef<number | undefined>(0);
    const isInternalInstance = useRef<boolean | undefined>(undefined);
    const isInternalSelected = useRef<boolean | undefined>(undefined);
    const [dailyCarrierIsInternal, setDailyCarrierIsInternal] = useState<DailyCarrier[]>([]);
    const [guideIsInternal, setGuideIsInternal] = useState<GuideDataForm>({});
    const [dispatchProgramItemIsInternal, setDispatchProgramItemIsInternal] =
    useState<DispatchProgramItemWithTransportOrder>({
        dispatch_program_item_id: dispatchProgramItemId,
        transport_order_id: 0,
        is_internal: true
    });

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    useEffect(() => {
        edit();
    }, []);

    
    useEffect(() => {

        if (dispatchProgramWithTransportOrder.transport_order_id == undefined) {
            transportOrderIdSelected.current = undefined;
        }


        if (
            dispatchProgramWithTransportOrder.is_internal &&
            dispatchProgramWithTransportOrder.transport_order_id &&
            dispatchProgramWithTransportOrder.transport_order_id != transportOrderIdSelected.current &&
            dispatchProgramWithTransportOrder.transport_order_id > 0
        ) {
            getTransportOrderByIdWithGuide(dispatchProgramWithTransportOrder.transport_order_id, {
                onSuccess: (response: ServiceResponse) => {
                    setErrorFields(undefined);
                    transportOrderIdSelected.current = dispatchProgramWithTransportOrder.transport_order_id;
                    setGuide({
                        ...response.data.guide,
                        url_file: response.data.guide?.file
                    });
                    setDailyCarrier({
                        carrier_id: response.data.transport_order.worker_id,
                        truck_id: response.data.transport_order.truck_id,
                        hopper_id: response.data.transport_order.hopper_id,
                        provider_id: response.data.transport_order.provider_id
                    });
                },
                onError: (response: ServiceResponse) => {
                    setDispatchProgramWithTransportOrder(
                        {
                            ...dispatchProgramWithTransportOrder,
                            transport_order_id: transportOrderIdSelected.current
                        }
                    )
                },
            });
        }
    }, [dispatchProgramWithTransportOrder.transport_order_id]);

    useEffect(() => {

        if (dispatchProgramWithTransportOrder.is_internal == undefined) {
            isInternalSelected.current = undefined;
        }
       
        if(isInternalSelected.current !== undefined && isInternalSelected.current !== dispatchProgramWithTransportOrder.is_internal){

            if(dispatchProgramWithTransportOrder.is_internal && isInternalInstance.current){

                isInternalSelected.current = dispatchProgramWithTransportOrder.is_internal;
                setErrorFields(undefined);
                setDispatchProgramWithTransportOrder(
                    {
                        ...dispatchProgramWithTransportOrder,
                        transport_order_id: dispatchProgramItemIsInternal.transport_order_id
                    }
                )
                setDailyCarrier(
                    {
                        ...dailyCarrier,
                        ...dailyCarrierIsInternal
                    }
                )
                setGuide(
                    {
                        ...guide,
                        ...guideIsInternal
                    }
                )
            }
            else if(
                (!dispatchProgramWithTransportOrder.is_internal && isInternalInstance.current) ||
                (dispatchProgramWithTransportOrder.is_internal && !isInternalInstance.current)
            ){
              
                isInternalSelected.current = dispatchProgramWithTransportOrder.is_internal;
                setErrorFields(undefined);
                setDispatchProgramWithTransportOrder(
                    {
                        ...dispatchProgramWithTransportOrder,
                        transport_order_id: 0
                    }
                )
                setDailyCarrier(
                    {
                        ...dailyCarrier,
                        provider_id: 0,
                        carrier_id: 0,
                        truck_id: 0,
                        hopper_id: 0,
                    }
                )
                setGuide(
                    {
                        ...guide,
                        file: undefined,
                        url_file: undefined,
                        folio: '',
                        quantity: 0,
                        issue_date: ''
                    }
                )
    
            }
            else if(!dispatchProgramWithTransportOrder.is_internal && !isInternalInstance.current){
    
                isInternalSelected.current = dispatchProgramWithTransportOrder.is_internal;
                setErrorFields(undefined);
                setDailyCarrier(
                    {
                        ...dailyCarrier,
                        ...dailyCarrierIsInternal
                    }
                )
                setGuide(
                    {
                        ...guide,
                        ...guideIsInternal,
                        issue_date: guideIsInternal.issue_date ? guideIsInternal.issue_date : ''
                    }
                )
    
            }

        }
     
        
    }, [dispatchProgramWithTransportOrder.is_internal]);
  
    // const editDispatchProgramItem = (_dailyCarrier: any) => {
    //     if (showLoading) showLoading('loading', 'Cargando datos...');
    //     editDispatchProgramItemWithTransportOrder(dispatchProgramItemId, {
    //         onSuccess: (response: ServiceResponse) => {
    //             setTransportOrders(response.data.transport_orders);

    //             setDispatchProgramWithTransportOrder({
    //                 ...dispatchProgramWithTransportOrder,
    //                 ...response.data.dispatch_program_item,
    //                 is_internal:
    //                     response.data.dispatch_program_item.transport_order_id == undefined &&
    //                     !_dailyCarrier.carrier_id
    //                         ? true
    //                         : response.data.dispatch_program_item.transport_order_id > 0
    //             });
    //             if (hideLoading) hideLoading();
    //         },
    //         onError: (response: ServiceResponse) => {
    //             if (hideLoading) hideLoading();

    //             toast.error(response.message, {
    //                 autoClose: 2500
    //             });

    //             if (onError) onError();
    //         }
    //     });
    // };

    const updateDispatchProgramItem = () => {
        if (showLoading) showLoading('loading', 'Actualizando posición de despacho...');
        updateDispatchProgramItemWithTransportOrder(
            dispatchProgramItemId,
            dispatchProgramWithTransportOrder,
            {
                onSuccess: (response: ServiceResponse) => {
                    if (hideLoading) hideLoading();
                    useReactConfirmAlert().successAlert({
                        title: 'Éxito',
                        message: response.message
                    });
                    toast.success(response.message, {
                        autoClose: 2500
                    });
                    if (onSaved) onSaved();
                },
                onError: (response: ServiceResponse) => {
                    if (hideLoading) hideLoading();
                    useReactConfirmAlert().errorAlert({
                        title: 'Error',
                        message: response.message
                    });
                },
                onFieldError: (errorFields: ServiceResponse) => {
                    if (hideLoading) hideLoading();
                    setErrorFields(errorFields.data);
                }
            }
        );
    };

    const edit = () => {

        if (showLoading) showLoading('loading', 'Cargando datos...');
        getDispatchProgramItemDailyCarriersAvailable(dispatchProgramItemId, {
            onSuccess: (response: ServiceResponse) => {
                
                setTransportOrders(response.data.transport_orders);
                setDailyCarrier(response.data.daily_carrier_selected);
                setProviders(response.data.providers);
                setCarriers(response.data.carriers);
                setTrucks(response.data.trucks);
                setHopper(response.data.hoppers);
                setGuide({
                    ...response.data.guide,
                    url_file: response.data.guide?.file
                });

                isInternalSelected.current = response.data.dispatch_program_item.transport_order_id == undefined &&
                !response.data.daily_carrier_selected.carrier_id
                    ? true
                    : response.data.dispatch_program_item.transport_order_id > 0

                setDispatchProgramWithTransportOrder({
                    ...dispatchProgramWithTransportOrder,
                    ...response.data.dispatch_program_item,
                    provider_id: response.data.daily_carrier_selected.provider_id,
                    is_internal: isInternalSelected.current
                        
                });
                transportOrderIdSelected.current = response.data.dispatch_program_item.transport_order_id ?? undefined;
                isInternalInstance.current = response.data.dispatch_program_item.transport_order_id ? true : false;
                
                setGuideIsInternal({
                    ...response.data.guide,
                    url_file: response.data.guide?.file
                })
                setDailyCarrierIsInternal({
                    ...dailyCarrierIsInternal,
                    ...response.data.daily_carrier_selected
                })
                setDispatchProgramItemIsInternal({
                    ...dispatchProgramItemIsInternal,
                    ...response.data.dispatch_program_item
                })
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                if (onError) onError(response.message);
            }
        });
    };

    const update = () => {
        if (showLoading) showLoading('loading', 'Actualizando posición de despacho...');
        updateDispatchProgramCarrierGuide(
            {
                dispatch_program_item_id: dispatchProgramItemId,
                provider_id: dailyCarrier.provider_id,
                carrier_id: dailyCarrier.carrier_id,
                truck_id: dailyCarrier.truck_id,
                hopper_id: dailyCarrier.hopper_id,

                file: guide.file,
                folio: guide?.folio,
                quantity: guide.quantity,
                issue_date: guide.issue_date,
            },
            {
                onSuccess: (response: ServiceResponse) => {
                    if (hideLoading) hideLoading();
                    toast.success(response.message);
                    if (onSaved) onSaved();
                },
                onError: (response: ServiceResponse) => {
                    if (hideLoading) hideLoading();
                    if (onError) onError(response.message);
                },
                onFieldError: (errorFields: ServiceResponse) => {
                    if (hideLoading) hideLoading();
                    setErrorFields(errorFields.data);
                }
            }
        );
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return (
        <>
            <DispatchProgramItemDailyCarrierForm
                dailyCarrier={dailyCarrier}
                setDailyCarrier={setDailyCarrier}
                providers={providers}
                carriers={carriers}
                trucks={trucks}
                hoppers={hoppers}
                dispatchProgramWithTransportOrderDataForm={dispatchProgramWithTransportOrder}
                setDispatchProgramWithTransportOrderDataForm={setDispatchProgramWithTransportOrder}
                transportOrders={transportOrders}
                errorFields={errorFields}
            />
            <DispatchProgramItemGuideForm
                guide={guide}
                setGuide={setGuide}
                errorFields={errorFields}
                dispatchProgramWithTransportOrderDataForm={dispatchProgramWithTransportOrder}
            />
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm
                        callbackCancel={cancel}
                        locked={fetchingSyncDispatchProgramDailyCarriers}
                    />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm
                        callbackSave={
                            dispatchProgramWithTransportOrder.is_internal
                                ? updateDispatchProgramItem
                                : update
                        }
                        locked={fetchingSyncDispatchProgramDailyCarriers}
                    />
                </div>
            </div>
        </>
    );
};

export default DispatchProgramItemCarrierGuideContainer;
