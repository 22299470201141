import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AuthContext } from '../../../../contexts/AuthContext';
import { SalesInvoiceDataForm, SalesInvoiceFilters } from '../../../../app/models/SalesInvoice';
import { RouterParams } from '../../../../app/shared/interfaces';
import Breadcrumbs, { BreadcrumbsItem } from '../../../../template/MainTheme/components/Breadcrumbs';
import DefaultCard from '../../../../components/default/DefaultCard';
import LazyLoading from '../../../../components/LazyLoading';
import { AppContext } from '../../../../contexts/AppContext';
import { ServiceResponse } from '../../../../app/services/shared/interfaces';
import { Tabs, Tab } from 'react-bootstrap';
import useSalesInvoiceService from '../../../../app/services/hooks/useSalesInvoiceService';
import SalesInvoiceFormContainer from '../SalesInvoiceFormContainer'; // El contenedor de la lógica del formulario
import SalesInvoiceDetailContainer from '../SalesInvoiceDetailContainer';
import useNavigationPage from '../../../../hooks/useNavigationPage';
import useSalesInvoiceDetailService from '../../../../app/services/hooks/useSalesInvoiceDetailService';
import SalesInvoiceCreate from '../SalesInvoiceCreate';
import { SalesInvoiceDetail, SalesInvoiceDetailFilters } from '../../../../app/models/SalesInvoiceDetail';
import SalesInvoicesEdit from '../SalesInvoiceEdit';
import useSalesInvoiceHistoryService from '../../../../app/services/hooks/useSalesInvoiceHistoryService';
import { SalesInvoiceHistoryDataForm, SalesInvoiceHistoryFilters } from '../../../../app/models/SalesInvoiceHistory';
import SalesInvoiceHistoryDataTable from '../tables/SalesInvoiceHistoryDataTable';
import SalesInvoiceHistoryContainer from '../SalesInvoiceHistoryContainer';
import { PaymentStatusDataForm, PaymentStatusesStatus } from '../../../../app/models/PaymentStatus';
import { SalesInvoiceStatus } from '../../../../app/models/SalesInvoiceStatus';
import DefaultCreatedModifiedUserForm from '../../../../components/default/DefaultCreatedModifiedUserForm';

interface Props {
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const SalesInvoiceEdit = ({ onSaved, onCancel, onError }: Props) => {
    const { id } = useParams<RouterParams>();
    const { navigationPage } = useNavigationPage();
    const { showLoading, hideLoading } = useContext(AppContext);
    const { auth } = useContext(AuthContext);

    const { editSalesInvoice, updateSalesInvoice, showSalesInvoice, fetchingEditSalesInvoice } = useSalesInvoiceService();
    const { getAllSalesInvoiceHistoriesByInvoice, fetchingGetSalesInvoiceHistoriesByInvoice} = useSalesInvoiceHistoryService();


    const [verifyingSalesInvoice, setVerifyingSalesInvoice] = useState<boolean>(true);

    const {getSalesInvoiceDetailsBySalesInvoice} = useSalesInvoiceDetailService(); 
    const [salesInvoice, setSalesInvoice] = useState<SalesInvoiceDataForm>({});
    const [salesInvoiceForm, setSalesInvoiceForm] = useState<SalesInvoiceDataForm>({});
    const [paymentStatuses, setPaymentStatuses] = useState<PaymentStatusDataForm[]>([]);
    const [paymentStatusesStatus, setPaymentStatusesStatus] = useState<PaymentStatusesStatus[]>([]);
    const [statuses, setStatuses] = useState<SalesInvoiceStatus[]>([]);

    const [salesInvoiceHistory, setSalesInvoiceHistory] = useState<SalesInvoiceHistoryDataForm[]>([]);
    const [filter, setFilter] = useState<SalesInvoiceFilters>({
        page: 1,
        per_page: 10,
        sort: 'position',
        order: 'desc'
    });


    const [filterDetails, setFilterDetails] = useState<SalesInvoiceDetailFilters>({
        page: 1,
        per_page: 10,
        sort: 'position',
        order: 'desc'
    });


    const [filterHistory, setFilterHistory]= useState<SalesInvoiceHistoryFilters>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'desc'
    });

    const [salesInvoicesDetail, setSalesInvoicesDetail] = useState<SalesInvoiceDetail[]>([]);
    const [totalRowsDetail, setTotalRowsDetail] = useState<number>(0);
    const [totalHistoryRows, setTotalHistoryRows] = useState<number>(0);



    useEffect(() => {
        // reloadModule();
        showDetails();
        edit(parseInt(id));
    }, [id]);


    useEffect(() => {
        reloadSalesInvoiceDetailTable();
    }, [filterDetails]);

    useEffect(() => {
        reloadTableHistory();
    }, [filterHistory]);

    const [errorFields, setErrorFields] = useState<any>();


    const edit = (id: number) => {
        editSalesInvoice(id, {
            onSuccess: (response: ServiceResponse) => {
                setSalesInvoice(response.data.sales_invoice);
                // setPaymentStatuses(response.data.payment_statuses);
                setVerifyingSalesInvoice(false)
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message);
                if (response.data.to_dashboard) {
                    navigationPage('/sales-invoice');
                }
            }
        });
    };


    const showDetails = () => {
        showSalesInvoice(Number(id), {
            onSuccess: (response: ServiceResponse) => {
                setSalesInvoiceForm(response.data.sales_invoice);
                setStatuses(response.data.statuses);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message);
            }
        });
    };

    const reloadTableHistory = () => {
        getAllSalesInvoiceHistoriesByInvoice(Number(id),filterHistory, {
            onSuccess: (response: ServiceResponse) => {
                setSalesInvoiceHistory(response.data.sales_invoice_histories);
                setTotalHistoryRows(response.data.total_rows);
                },
                onError: (response: ServiceResponse) => {
                    toast.error(response.message, {
                        autoClose: 2500
                    });
                }
            
        });
    };


    const reloadSalesInvoiceDetailTable = () => {
        getSalesInvoiceDetailsBySalesInvoice(filter, Number(id), {
            onSuccess: (response: ServiceResponse) => {
                setSalesInvoicesDetail(response.data.sales_invoice_details);
                setPaymentStatuses(response.data.payment_statuses);
                setPaymentStatusesStatus(response.data.statuses)
                setTotalRowsDetail(response.data.total_rows);

            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };


    if (verifyingSalesInvoice) {
        return (
            <DefaultCard>
                <LazyLoading height={300} />
            </DefaultCard>
        );
    }

    const breadcrumbs: BreadcrumbsItem[] = [
        { name: 'Facturas de venta', url: '/sales-invoice', isActive: false },
        { name: `Editar Factura de venta #${salesInvoice.invoice_number}`, url: `/sales-invoices/${id}/edit`, isActive: true }
    ];


    return (
        <>
            <Breadcrumbs pageSection={`Editar Factura de venta #${salesInvoice.invoice_number}`} breadcrumbs={breadcrumbs} />

            <Tabs defaultActiveKey="general-data" id="sales-invoice-tabs" className="mb-3">
                <Tab eventKey="general-data" title="Datos Generales">
                    <div className="row">
                        {/* Tabla de detalles de la factura */}
                        <div className="col-12">
                            <SalesInvoiceDetailContainer
                                salesInvoiceId={parseInt(id)}
                                salesInvoice={salesInvoice}
                                setSalesInvoice={setSalesInvoice}
                                salesInvoiceForm={salesInvoiceForm}
                                setSalesInvoiceForm={setSalesInvoiceForm}
                                setSalesInvoiceDetailTable={setSalesInvoicesDetail}
                                setSalesInvoiceHistoryTable={setSalesInvoiceHistory}
                                reloadSalesInvoiceDetailTable={reloadSalesInvoiceDetailTable}
                                reloadSalesInvoice={edit}
                                filterDetail={filterDetails}
                                setFilterDetail={setFilterDetails}
                                filterHistory={filterHistory}
                                setFilterHistory={setFilterHistory}
                                reloadHistoryTable={reloadTableHistory}
                                reloadDetail={showDetails}
                                paymentStatuses={paymentStatuses}
                                setPaymentStatusesStatus={setPaymentStatusesStatus}
                                paymentStatusesStatus={paymentStatusesStatus}
                                statuses={statuses}
                                setStatuses={setStatuses}
                                totalRowsDetail={totalRowsDetail}
                            />
                        </div>
                    </div>
                </Tab>

                <Tab eventKey="history" title="Historial">
                    <div className="col-12">
                            <SalesInvoiceHistoryContainer
                                salesInvoiceId={parseInt(id)}
                                salesInvoiceHistory={salesInvoiceHistory}
                                totalRowsHistory={totalHistoryRows}
                                filterHistory={filterHistory}
                                setFilterHistory={setFilterHistory}
                                setSalesInvoiceHistoryTable={setSalesInvoiceHistory}
                                reloadSalesInvoiceHistoryTable={reloadTableHistory}
                            />

                    </div>
                </Tab>
            </Tabs>

        </>
    );
};

export default SalesInvoiceEdit;
