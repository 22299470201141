import React, { useEffect, useState } from 'react';
import SelectTwo, { SelectTwoMapperOptions } from '../../../../components/SelectTwo';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import { SalesInvoiceDataForm, SalesInvoicePaymentDataForm } from '../../../../app/models/SalesInvoice'; 
import ButtonTableAction from '../../../../components/buttons/ButtonTableAction';
import { Society } from '../../../../app/models/Society';
import { Client } from '../../../../app/models/Client';
import LazyLoading from '../../../../components/LazyLoading';
import { momentParseDate } from '../../../../helpers';


interface Props {
    salesInvoice: SalesInvoiceDataForm;
    societies: Society[];
    clients: Client[];
    disabled?: boolean;
    setSalesInvoice: (salesInvoice: SalesInvoiceDataForm) => void;
    availableStatuses: SalesInvoicePaymentDataForm[];
    errorFields?: any;
    loadingClient?: boolean;
}

const SalesInvoiceForm = ({ 
     salesInvoice,
     setSalesInvoice,
      availableStatuses, 
      societies,
      clients,
      disabled = false,
      loadingClient,

      errorFields }: Props) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors, errors } = useHandleErrorFields();

    const [addedPayments, setAddedPayments] = useState<SalesInvoicePaymentDataForm[]>([]);
    const [selectedPayment, setSelectedPayment] = useState<SalesInvoicePaymentDataForm | null>(null);
    const [totalAmount, setTotalAmount] = useState<number>(0);

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields, setErrors]);

    const parseCurrencyToNumber = (currency: string) => {
        return parseFloat(currency.replace(/\./g, '').replace(/,/g, '').replace(/[^0-9.-]+/g, ''));
    };

    const formatCurrency = (amount: number) => {
        return new Intl.NumberFormat('es-CL', {
            style: 'currency',
            currency: 'CLP',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        }).format(amount);
    };

    const mappedStatuses = availableStatuses.map((status) => ({
        id: status.id,
        name: `#${status.id} - ${status.client_name} - Total: ${status.total}`,
        total: status.total,
        client_name: status.client_name,
        total_tax: status.total_tax,
        total_neto: status.total_neto,
    }));

    const handleSelectChange = (name: string, value: any) => {
        const selected = mappedStatuses.find((status) => status.id === value.value);
        setSelectedPayment(selected || null);
    };

    const handleChangeSelectTwoClient = (name: string, value: number | string | null) => {
        setSalesInvoice({
            ...salesInvoice,
            [name]: value,
            client_email: clients.find((client) => client.id == value)?.email || ''
        });
    };

    const handleChangeSelectTwo = (name: string, value: number | string | null) => {
        if(name === 'society_id'){
            setErrors(
                {
                    ...errors,
                    client_id:''
                }
            )
        }
        setSalesInvoice({ ...salesInvoice, [name]: value });
    };

    const handleChange = (
        e:
            | React.ChangeEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLSelectElement>
            | React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        const { name, value } = e.target;

        setSalesInvoice({ ...salesInvoice, [name]: value });
    };


    const addPayment = () => {
        if (selectedPayment && !addedPayments.some(p => p.id === selectedPayment.id)) {
            setAddedPayments([...addedPayments, selectedPayment]);
        }
    };

    const removePayment = (id: number) => {
        const updatedPayments = addedPayments.filter(payment => payment.id !== id);
        setAddedPayments(updatedPayments);
    };

    useEffect(() => {
        const total = addedPayments.reduce((sum, payment) => {
            const paymentTotal = typeof payment.total === 'string' 
                ? parseCurrencyToNumber(payment.total)
                : payment.total;
            return sum + paymentTotal;
        }, 0);

        const paymentStatusesIds = addedPayments.map(payment => payment.id);

        setTotalAmount(total);

        setSalesInvoice({
            ...salesInvoice,
            total_sale: total,
            payment_statuses_id: paymentStatusesIds
        });
    }, [addedPayments, setSalesInvoice]);

    

    return (
        <div className="sales-invoice-form">

        <div className="mb-2 row">
                <label className="col-md-3 col-form-label">Sociedad</label>
                <div className="col-md-9">
                    <SelectTwo
                        name="society_id"
                        id="society_id"
                        inputValue={salesInvoice.society_id}
                        options={SelectTwoMapperOptions(societies)}
                        hasError={fieldHasError('society_id') !== ''}
                        onChange={(value: any) => handleChangeSelectTwo('society_id', value.value)}
                        placeholder={'Seleccione una sociedad'}
                        onFocus={() => onFocusRemove('society_id')}
                        disabled={disabled}
                    />
                    <div className="invalid-feedback" style={{ display: 'flex' }}>
                        {fieldErrorMessage('society_id')}
                    </div>
                </div>
            </div>

                    <div className="mb-2 row">
                        <label className="col-md-3 col-form-label">Cliente</label>
                        <div className="col-md-9">
                            <SelectTwo
                                name="client_id"
                                id="client_id"
                                inputValue={salesInvoice.client_id}
                                options={SelectTwoMapperOptions(clients, 'id', ['business_name'])}
                                hasError={fieldHasError('client_id') !== ''}
                                onChange={(value: any) =>
                                    handleChangeSelectTwoClient('client_id', value.value)
                                }
                                placeholder={'Seleccione un cliente'}
                                onFocus={() => onFocusRemove('client_id')}
                                disabled={disabled}
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('client_id')}
                            </div>
                        </div>
                    </div>

                    {!disabled && ( 
                    <div className="mb-2 row">
                    <label className="col-md-3 col-form-label">Folio Factura</label>
                    <div className="col-md-9">
                        <input
                            className={`form-control ${fieldHasError('folio')}`}
                            name="folio"
                            id="folio"
                            type="text"
                            value={salesInvoice.folio}
                            onChange={handleChange}
                            placeholder="Ingrese el folio de la factura"
                            onFocus={() => onFocusRemove('folio')}
                        />
                        <div className="invalid-feedback" style={{ display: 'flex' }}>
                            {fieldErrorMessage('folio')}
                        </div>
                    </div>
                    </div>

                    )}
                    {!disabled && ( 

                    <div className="mb-2 row">
                        <label className="col-md-3 col-form-label">Fecha de emisión</label>
                        <div className="col-md-9">
                            <input
                                type="date"
                                className="form-control"
                                id="issue_date"
                                name="issue_date"
                                value={salesInvoice.issue_date}
                                onChange={handleChange}
                                onFocus={() => onFocusRemove('issue_date')}
                                // disabled={disabled}
                            />
                            <div className="invalid-feedback" style={{ display: 'flex' }}>
                                {fieldErrorMessage('issue_date')}
                            </div>
                        </div>
                    </div>
                    )}

            <label className="col-md-6 col-form-label">Documento de Factura</label>
            <div className="d-flex mb-3 align-items-stretch">
            <div className="flex-grow-1 me-2">
                <SelectTwo
                    name="payment_status_id"
                    id="payment_status_id"
                    inputValue={selectedPayment ? selectedPayment.id : null}
                    hasError={fieldHasError('payment_status') !== ''}
                    options={SelectTwoMapperOptions(mappedStatuses, 'id', ['name'])}
                    onChange={(value: any) => handleSelectChange('payment_status_id', value)}
                    onFocus={() => onFocusRemove('payment_statuses_id')}
                    placeholder="Seleccionar Documento"
                />
                <div className="invalid-feedback" style={{ display: 'flex' }}>
                    {fieldErrorMessage('payment_statuses_id')}
                </div>
            </div>

            <button
                type="button"
                className="btn btn-primary"
                onClick={addPayment}
                disabled={!selectedPayment}
            >
                Agregar Documento
            </button>
        </div>
            <div className="table-form">
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th>Nro Documento</th>
                            {/* <th>Cliente</th> */}
                            <th>Neto</th>
                            <th>Impuesto</th>
                            <th>Total</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {addedPayments.map((payment) => (
                            <tr key={payment.id}>
                                <td data-label="Nro">#{payment.id}</td>
                                {/* <td data-label="Cliente">{payment.client_name}</td> */}
                                <td data-label="Neto">{formatCurrency(parseCurrencyToNumber(payment.total_neto))}</td>
                                <td data-label="Impuesto">{formatCurrency(parseCurrencyToNumber(payment.total_tax))}</td>
                                <td data-label="Total">{payment.total}</td>
                                <td data-label="Acciones">
                                    <ButtonTableAction
                                        callbackFunction={() => removePayment(payment.id ?? -1)}
                                        classIcon={'mdi mdi-delete'}
                                        colorIcon={'text-danger'}
                                        errorMessage={'No se puede eliminar este registro.'}
                                        title={'Eliminar'}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <div className="total mt-3 d-flex justify-content-end">
                <span className="me-2">Total:</span>
                <span>{formatCurrency(totalAmount)}</span>
            </div>
        </div>
    );
};

export default SalesInvoiceForm;
