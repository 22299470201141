import React, { useEffect } from 'react';
import { PaymentStatusDataForm } from '../../../../app/models/PaymentStatus';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import { momentParseDate } from '../../../../helpers';

type Props = {
    paymentStatus: PaymentStatusDataForm;
    setPaymentStatus: (PaymentStatus: PaymentStatusDataForm) => void;
    errorFields?: any;
};

const PaymentStatusForm = ({ paymentStatus, setPaymentStatus, errorFields }: Props) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
    ) => {
        const { name, value } = e.target;
        setPaymentStatus({ ...paymentStatus, [name]: value });
    };

    return (
        <>

            <div className="form-group mt-2">
                <label htmlFor="client">Cliente</label>
                <input
                    type="text"
                    className="form-control"
                    id="client"
                    name="client"
                    value={paymentStatus.client}
                    onChange={handleChange}
                    disabled

                />
            </div>
            <div className="form-group mt-2">
                <label htmlFor="contactEmail">Email de Contacto</label>
                <input
                    type="email"
                    className="form-control"
                    id="contact_email"
                    name="contact_email"
                    value={paymentStatus.contact_email}
                    onChange={handleChange}
                    onFocus={() => onFocusRemove('contact_email')}
                />
                <div className="invalid-feedback" style={{ display: 'flex' }}>
                    {fieldErrorMessage(`contact_email`)}
                </div>
            </div>
            <div className="form-group mt-2">
                <label htmlFor="contactPhone">Teléfono de Contacto</label>
                <input
                    className="form-control"
                    name="contact_phone"
                    value={paymentStatus.contact_phone}
                    onChange={handleChange}
                    disabled
                />
            </div>
            <div className="form-group mt-2">
                <label htmlFor="paymentConditionId">Condición de Pago</label>
                <input
                    className="form-control"
                    name="payment_condition"
                    value={paymentStatus.payment_condition}
                    onChange={handleChange}
                    disabled
                />
            </div>
            <div className="form-group mt-2">
                <label htmlFor="createdAt">Fecha de Emisión</label>
                <input
                    type="date"
                    className="form-control"
                    id="date_of_issue"
                    name="date_of_issue"
                    value={paymentStatus.date_of_issue}
                    onChange={handleChange}
                    onFocus={() => onFocusRemove('date_of_issue')}
                />
                <div className="invalid-feedback" style={{ display: 'flex' }}>
                    {fieldErrorMessage(`date_of_issue`)}
                </div>
            </div>
        </>
    );
};

export default PaymentStatusForm;
