import React, { useContext, useEffect, useRef, useState } from 'react';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import LazyLoading from '../../../components/LazyLoading';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { SocietyProviderDataForm } from '../../../app/models/SocietyProvider';
// import { ProviderContact } from '../../../app/models/ProviderContact';
import useSocietyProviderService from '../../../app/services/hooks/useSocietyProviderService';
import SocietyProviderForm from './forms/SocietyProviderForm';
import { Provider } from '../../../app/models/Provider';
// import useBusinessNameContactService from '../../../app/services/hooks/useBusinessNameContactService';

interface Props {
    societyId?: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const SocietyProviderCreate = ({ societyId, onSaved, onCancel, onError }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);

    // const [ProviderContacts, setProviderContacts] = useState<ProviderContact[]>([]);
    const [Providers, setProviders] = useState<Provider[]>([]);
    const [societyProvider, setSocietyProvider] = useState<SocietyProviderDataForm>(
        {
            society_id: societyId,
            provider_id: 0,
            account_number: ''
        }
    )

    const providerIdSelected = useRef<number | undefined>(0);

    const [errorFields, setErrorFields] = useState<any>();

    const { fetchingStoreSocietyProvider, storeSocietyProvider, fetchingCreateSocietyProvider, createSocietyProvider } =
        useSocietyProviderService();

    // const {
    //     fetchingGetBusinessNameContactsByProvider,
    //     getBusinessNameContactByProvider
    // } = useBusinessNameContactService()

    useEffect(() => {
        if (showLoading) showLoading('loading', 'Cargando datos...');

        createSocietyProvider({
            onSuccess: (response: ServiceResponse) => {

                const providers = response.data.providers.map((provider: Provider) => {
                    return {
                      ...provider,
                      account_number: `${societyId}-${provider.business_name}`
                    };
                  });
               
                setProviders(providers);
               
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    }, []);

    useEffect(() => {

        if (societyProvider.provider_id == undefined) {
            providerIdSelected.current = undefined;
        }

        if (societyProvider.provider_id && societyProvider.provider_id != providerIdSelected.current) {

            // getBusinessNameContactByProvider(societyProvider.provider_id, {
            //         onSuccess: (response: ServiceResponse) => {

            //             providerIdSelected.current = societyProvider.provider_id ?? 0;
            //             setErrorFields(undefined);

            //             setProviderContacts(response.data.provider_contacts);
            //             setSocietyProvider(
            //                 {
            //                     ...societyProvider,
            //                     business_name_contact_id: undefined
            //                 }
            //             )
                       
            //         },
            //         onError: (response: ServiceResponse) => {
            //             setSocietyProvider({
            //                 ...societyProvider,
            //                 provider_id: providerIdSelected.current
            //             });
            //         }
            //     })
                
            }
    }, [societyProvider.provider_id]);

    const store = () => {
   
        if (showLoading) showLoading('loading', 'Guardando proveedor...');
        storeSocietyProvider(societyProvider, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    // if (errorGetData !== '') {
    //     return <div>{errorGetData}</div>;
    // }

    return fetchingCreateSocietyProvider ? (
        <LazyLoading height={300} />
    ) : (
        <>
            <div className="row">
                <div className="col-12">
                    <SocietyProviderForm
                        societyProviderForm={societyProvider}
                        setSocietyProviderForm={setSocietyProvider}
                        // providerContacts={ProviderContacts}
                        providers={Providers}
                        // loadingProviderContact={fetchingGetBusinessNameContactsByProvider}
                        errorFields={errorFields}
                        setErrorFields={setErrorFields}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetchingStoreSocietyProvider} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={store} locked={fetchingStoreSocietyProvider} />
                </div>
            </div>
        </>
    );
};

export default SocietyProviderCreate;
